
# coffeelint: disable=max_line_length
$.ajaxSetup headers: 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')

shareClose = ()->
  $('.br-state-2').addClass 'hidden'
  $('.br-state-3').addClass 'hidden'
  $('.br-state-4').addClass 'hidden'

shareOpen = ()->
  $('.br-state-2').removeClass 'hidden'

sharedirection = (formname) ->
  to_email = $('input[name="to_email"]').val()
  to = $('.share-message').html().replace('%s', to_email)
  $('.share-message').html to
  msg = $('#' + formname).serializeArray()
  $('.glyphicon.glyphicon-refresh').removeClass 'hidden'
  $('.directions-share-text').addClass 'hidden'
  $('.br-submit').prop 'disabled', true
  $.post '', msg, (data) ->
    if data == 'true'
      $('.br-state-2').addClass 'hidden'
      $('.br-state-4').removeClass 'hidden'
      $('.glyphicon.glyphicon-refresh').addClass 'hidden'
      $('.directions-share-text').removeClass 'hidden'
      $('.br-submit').prop 'disabled', false
    else
      $('.br-state-2').addClass 'hidden'
      $('.br-state-3').removeClass 'hidden'
      $('.glyphicon.glyphicon-refresh').addClass 'hidden'
      $('.directions-share-text').removeClass 'hidden'
      $('.br-submit').prop 'disabled', false

$('#br-contact-us-form, #br-company-contact, #br-modal-add-company-form, .br-article-form').validator()

dirty = false
$('#br-modal-add-company-form :input').change ->
  dirty = true
  return

window.onbeforeunload = ->
  if dirty
    return 'Bu sayfadan çıkarsanız işleminiz gerçekleşmeyecek, emin misiniz?'
  return

#$('input[type="tel"]').mask '0 (999) 999 99 99'
$('input[name="card_number"]').mask '9999-9999-9999-9999'
$('input[name="card_security_code"]').mask '999'
$('input[name="tax_number"]').mask '9999999999'

$('input[name="subscribe_city"], input[name="subscribe_email"], input[name="q"], input[name="area"]').keydown (e) ->
  if e.keyCode == 13
    e.preventDefault()
    pressTab = jQuery.Event('keydown', keyCode: 9)
    $(this).trigger pressTab
    $(this).blur()
    $(this).closest('form').find('.search-btn').trigger 'click'

mailsubscribe = (formname, uri) ->
  if $('input[name="subscribe_city"]').val().length > 0
    formValidation formname, uri, false

$('input').focus ()->
  $('.fix-errors').addClass 'hidden'

recaptchaCallback = ()->
  $('.captcha-error').addClass 'hidden'

placeholderAdded = true

$('#modalForm').submit (e) ->
  url = '/modalform'
  inputName = $('[name="modal_name"]').val()
  inputMail = $('[name="modal_email"]').val()
  if inputName && inputMail
    if !$('.form-group').hasClass 'has-error'
      $.ajax
        type: 'POST'
        url: url
        data: $('#modalForm').serialize()
        success: (data) ->
          if data == "true"
            loadmodal '/googlesuccess'
            $('#homePageModal').modal 'hide'
          else
            loadmodal '/fail'
        error: (data) ->
          loadmodal '/fail'
        beforeSend: (data) ->
          $('#homePageModal').find('.btn').html '<i class="fa fa-spin fa-refresh"></i>'
          $('#homePageModal').find('.btn').addClass 'disabled'
  else
    if placeholderAdded
      namePlaceholder = $('[name="modal_name"]').attr 'placeholder'
      emailPlaceholder = $('[name="modal_email"]').attr 'placeholder'
      $('[name="modal_name"]').attr 'placeholder', namePlaceholder+'?'
      $('[name="modal_email"]').attr 'placeholder', emailPlaceholder+'?'
      placeholderAdded = false
  e.preventDefault()
  return

formValidation = (formname, uri, recaptcha)->
  if recaptcha==undefined
    recaptcha=true
  if recaptcha
    v = grecaptcha.getResponse()
    if v.length == 0
      $('.captcha-error').removeClass 'hidden'
    if v.length != 0
      $('.captcha-error').addClass 'hidden'
  buttonDisabled = $('button[type="submit"]').hasClass 'disabled'
  selectVal = $('select').val()
  if buttonDisabled
    $('.fix-errors').removeClass 'hidden'
    if selectVal == null
      $('select[name="employee_size_id"]').parent().addClass 'has-error-select'
      $('select[name="categories[]"]').parent('.br-tag-input').removeClass 'has-error-tag'
      $('select[name="categories[]"]').parent('.br-tag-input').addClass 'has-error-tag'
  else
    formgonder(formname, uri)
    $('.fix-errors').addClass 'hidden'

$('select[required]').change ()->
  if $(this).find('option').val() == 0
    $(this).parent().addClass 'has-error-select'
  else
    $(this).parent().removeClass 'has-error-select'

formgonder = (formname, uri) ->
  submitText = $('.br-submit').text()
  $('.br-submit').text '...'
  $('#br-modal').modal 'hide'
  $('button').attr 'disabled', ''
  if $('input[name="authorized_phone"]').length > 0
    authorized_phone = $('input[name="authorized_phone"]').val().replace(/(\s|\(|\)|^[0])/g, '')
    $('input[name="authorized_phone"]').val authorized_phone
    $(formname).submit()
  if $('input[name="customer_phone"]').length > 0
    customer_phone = $('input[name="customer_phone"]').val().replace(/(\s|\(|\)|^[0])/g, '')
    $('input[name="customer_phone"]').val customer_phone
    $(formname).submit()
  if $('input[name="business_phone"]').length > 0
    business_phone = $('input[name="business_phone"]').val().replace(/(\s|\(|\)|^[0])/g, '')
    $('input[name="business_phone"]').val business_phone
    $(formname).submit()
  if $('input[name="card_number"]').length > 0
    card_number = $('input[name="card_number"]').val().replace(/(-)/g, '')
    $('input[name="card_number"]').val card_number
    $(formname).submit()
  if $('input[name="phone"]').length > 0
    phone = $('input[name="phone"]').val().replace(/(\s|\(|\)|^[0]|_)/g, '')
    $('input[name="phone"]').val phone
    $(formname).submit()
  if $('input[name="website"]').length > 0
    website = $('input[name="website"]')
    if website.val().substr(0, 4) != 'http' and website.val() != ''
      website.val 'http://' + website.val()
      $(formname).submit()
  if $('#super_page_email_subscription').length > 0
    email_subs = $('#super_page_email_subscription')
    email_subs_hidden = $('#super_page_email_subscriptionHidden')
    if email_subs.is(':checked')
      email_subs_hidden.val '1'
      $(formname).submit()
    else
      email_subs_hidden.val '0'
      $(formname).submit()
  if $('#web_sites_email_subscription').length > 0
    email_subs = $('#web_sites_email_subscription')
    email_subs_hidden = $('#web_sites_email_subscriptionHidden')
    if email_subs.is(':checked')
      email_subs_hidden.val '1'
      $(formname).submit()
    else
      email_subs_hidden.val '0'
      $(formname).submit()
  if $('#digital_maps_email_subscription').length > 0
    email_subs = $('#digital_maps_email_subscription')
    email_subs_hidden = $('#digital_maps_email_subscriptionHidden')
    if email_subs.is(':checked')
      email_subs_hidden.val '1'
      $(formname).submit()
    else
      email_subs_hidden.val '0'
      $(formname).submit()
  if $('#digital_ads_email_subscription').length > 0
    email_subs = $('#digital_ads_email_subscription')
    email_subs_hidden = $('#digital_ads_email_subscriptionHidden')
    if email_subs.is(':checked')
      email_subs_hidden.val '1'
      $(formname).submit()
    else
      email_subs_hidden.val '0'
      $(formname).submit()
  if $('#digital_ads_facebook_email_subscription').length > 0
    email_subs = $('#digital_ads_facebook_email_subscription')
    email_subs_hidden = $('#digital_ads_facebook_email_subscriptionHidden')
    if email_subs.is(':checked')
      email_subs_hidden.val '1'
      $(formname).submit()
    else
      email_subs_hidden.val '0'
      $(formname).submit()
  if $('#digital_ads_adwords_email_subscription').length > 0
    email_subs = $('#digital_ads_adwords_email_subscription')
    email_subs_hidden = $('#digital_ads_adwords_email_subscriptionHidden')
    if email_subs.is(':checked')
      email_subs_hidden.val '1'
      $(formname).submit()
    else
      email_subs_hidden.val '0'
      $(formname).submit()
  if $('#br-want-info').length > 0
    want_info = $('#br-want-info')
    want_info_hidden = $('#br-want-info-hidden')
    if want_info.is(':checked')
      want_info_hidden.val 1
      $(formname).submit()
    else
      want_info_hidden.val 0
      $(formname).submit()
  if $('.cash').is(':checked')
    $('[name="payment_method[cash]"]').val 1
  else
    $('[name="payment_method[cash]"]').val 0

  if $('.visa').is(':checked')
    $('[name="payment_method[visa]"]').val 1
  else
    $('[name="payment_method[visa]"]').val 0

  if $('.mastercard').is(':checked')
    $('[name="payment_method[mastercard]"]').val 1
  else
    $('[name="payment_method[mastercard]"]').val 0

  if $('.multinet').is(':checked')
    $('[name="payment_method[multinet]"]').val 1
  else
    $('[name="payment_method[multinet]"]').val 0

  if $('.ticket').is(':checked')
    $('[name="payment_method[ticket]"]').val 1
  else
    $('[name="payment_method[ticket]"]').val 0

  if $('.sodexo').is(':checked')
    $('[name="payment_method[sodexo]"]').val 1
  else
    $('[name="payment_method[sodexo]"]').val 0

  form = $('#' + formname).serializeArray()
  $.post uri, form, (data) ->
    if data == 'true'
      if formname == 'br-company-contact' || formname == 'br-contact-us-form' || formname == 'br-article-form'
        loadmodal '/paidcontactsuccess'
      else if formname == 'br-payment-form'
        loadmodal '/paymentsuccess'
      else
        loadmodal '/success'
      $('button').removeAttr 'disabled', ''
      $('.br-submit').text submitText
      # $(':input', 'form').not(':button, :submit, :reset, :hidden').val('').removeAttr('checked').removeAttr 'selected'
      # $('select').find('option:first-of-type').prop 'selected', true
      # $('.category-select2').select2 'val', 'All'
      # $('.brands-select2').select2 'val', 'All'
      $('#br-credit').prop 'checked', true
      $('#br-eft').prop 'checked', false
      $('.br-credit-form').removeClass 'hidden'
      $('.br-eft-info').addClass 'hidden'
    else
      loadmodal '/fail'
      $('button').removeAttr 'disabled', ''
      $('.br-submit').text submitText
      # TODO: modeller geri yüklendiğinde kaldırılacak data-attribute eklenecek
      #$('#br-modal').addClass 'br-modal-fail ' + formname
    return

$(document.body).on 'hidden.bs.modal', '.br-modal-fail', (event) ->
  $('#br-modal').removeClass 'br-modal-fail'
  if $('#br-modal').hasClass('br-modal-add-company-form')
    $('#br-modal').removeClass 'br-modal-add-company-form'
    loadmodal '/addcompany'
  else
    $('#br-modal').removeClass 'br-modal-edit-company-form'
    loadmodal '/updatecompany' + window.location.pathname
  return

htmlLang = $('html').attr 'lang'
if htmlLang is 'tr'
  $('.category-select2').select2
    language: 'tr'
    minimumInputLength: 3
    maximumSelectionLength: 2
    ajax:
      url: '/data/categories'
      dataType: 'json'
      delay: 250
      data: (params) ->
        {
          q: params.term
          page: params.page
        }
      processResults: (data, params) ->
        # parse the results into the format expected by Select2
        # since we are using custom formatting functions we do not need to
        # alter the remote JSON data, except to indicate that infinite
        # scrolling can be used
        params.page = params.page or 1
        newData = $.map(data, (obj) ->
          obj.id = obj._id
          obj.text = obj.category_name
          obj
          )
        {
          results: newData
          pagination: more: params.page * 30 < data.total_count
        }
  $('.brands-select2').select2
    language: 'tr'
    minimumInputLength: 3
    maximumSelectionLength: 2
    ajax:
      url: '/data/brands'
      dataType: 'json'
      delay: 250
      data: (params) ->
        {
          q: params.term
          page: params.page
        }
      processResults: (data, params) ->
        # parse the results into the format expected by Select2
        # since we are using custom formatting functions we do not need to
        # alter the remote JSON data, except to indicate that infinite
        # scrolling can be used
        params.page = params.page or 1
        newData = $.map(data, (obj) ->
          obj.id = obj._id
          obj.text = obj.brand_name
          obj
          )
        {
          results: newData
          pagination: more: params.page * 30 < data.total_count
        }
else
  $('.category-select2').select2
    language: 'en'
    minimumInputLength: 3
    maximumSelectionLength: 2
    ajax:
      url: '/data/categories'
      dataType: 'json'
      delay: 250
      data: (params) ->
        {
          q: params.term
          page: params.page
        }
      processResults: (data, params) ->
        # parse the results into the format expected by Select2
        # since we are using custom formatting functions we do not need to
        # alter the remote JSON data, except to indicate that infinite
        # scrolling can be used
        params.page = params.page or 1
        newData = $.map(data, (obj) ->
          obj.id = obj._id
          obj.text = obj.category_name
          obj
          )
        {
          results: newData
          pagination: more: params.page * 30 < data.total_count
        }
  $('.brands-select2').select2
    language: 'en'
    minimumInputLength: 3
    maximumSelectionLength: 2
    ajax:
      url: '/data/brands'
      dataType: 'json'
      delay: 250
      data: (params) ->
        {
          q: params.term
          page: params.page
        }
      processResults: (data, params) ->
        # parse the results into the format expected by Select2
        # since we are using custom formatting functions we do not need to
        # alter the remote JSON data, except to indicate that infinite
        # scrolling can be used
        params.page = params.page or 1
        newData = $.map(data, (obj) ->
          obj.id = obj._id
          obj.text = obj.brand_name
          obj
          )
        {
          results: newData
          pagination: more: params.page * 30 < data.total_count
        }

$('input#city_area').focusout ->
  match = false
  $.ajaxSetup async: false
  $.getJSON '//' + window.location.host + '/assets/data/where.json', (data) ->
    $.each data, (i, v) ->
      where = $('input#city_area').val().split(', ')
      if v.search == where[0]
        match = true
        return false
      return
    return
  $.ajaxSetup async: true
  if !match
    $('input#city_area').typeahead 'val', ''
  return

# TODO: Footerdaki signup'a tıklandığında signup tabi açık şekilde gelecek modal
$('#sign-up-tab').click ->
  $('#br-tab-signup').addClass 'active'
  $('#br-tab-login').removeClass 'active'

$(document).ajaxStart ->
  $('.ajax-loader').removeClass 'hidden'

$(document).ajaxStop ->
  $('.ajax-loader').addClass 'hidden'

$(document).ajaxComplete ->
  $('.ajax-loader').addClass 'hidden'

$('.select2-search__field').focus ()->
  $(this).parent('.br-tag-input').removeClass 'has-error-tag'

$('.br-tag-required').change ()->
  hasTag = $(this).has '.select2-selection__choice'
  if hasTag
    $(this).removeClass 'has-error-tag'

$('.br-tag-required').find('.select2-search__field').blur ()->
  tagVar = $('.br-tag-required').has('.select2-selection__choice').length
  inputVal = $(this).find('input').val()
  if tagVar
    $('.br-tag-required').removeClass 'has-error-tag'
  else
    $('.br-tag-required').addClass 'has-error-tag'
  if inputVal
    $('.br-tag-required').removeClass 'has-error-tag'
  else
    $('.br-tag-required').addClass 'has-error-tag'

$('button[type="submit"]').click ()->
  termsChecked = $('#br-eula').is ':checked'
  if !termsChecked
    $('#br-eula').addClass 'has-error'
  else
    $('#br-eula').removeClass 'has-error'

# $('input[type="tel"], input[name="tax_number"], input[name="card_number"], input[name="card_expire_date_month"], input[name="card_expire_date_year"], input[name="card_security_code"]').keyup () ->
  # text = $(this).val()
  # text = text.replace(/[^0-9]/g, '')
  # if text != $(this).val()
  #   $(this).val text

# $('input[name="lat"], input[name="lng"]').keyup () ->
#   text = $(this).val()
#   text = text.replace(/[^0-9\.]/g, '')
#   if text != $(this).val()
#     $(this).val text

$('input[type="tel"]').keyup () ->
  zero = $(this).val()
  zero = zero.replace /^(0)/, ''
  if zero != $(this).val()
    $(this).val zero
  text = $(this).val()
  text = text.replace(/[^0-9]/g, '')
  if text != $(this).val()
    $(this).val text

$('input[name="card_expire_date_month"]').keyup () ->
  text = $(this).val().length
  if text > 2
    return false


$('#br-profile-form').submit (e) ->
  url = '/profile/'+$(this).attr 'data-id'
  $.ajax
    type:'PUT'
    url: url
    data: $(this).serialize()
    success: (data) ->
      console.log data
      if data == "true"
        loadmodal '/success'
        $('#homePageModal').modal 'hide'
      else
        loadmodal '/fail'
    error: (data) ->
      loadmodal '/fail'
    beforeSend: (data) ->
      $('#homePageModal').find('.btn').html '<i class="fa fa-spin fa-refresh"></i>'
      $('#homePageModal').find('.btn').addClass 'disabled'

  return false